<template>
  <div class="l-wrapper">
    <div class="q-pa-md">
      <q-input
        outlined
        label="Cari Tipe Aplikasi"
        v-model="pagination.searchTerm"
      >
        <template v-slot:append>
          <q-icon name="search" />
        </template>
      </q-input>
    </div>
    <q-scroll-area class="l-grow bg-grey-2">
      <q-markup-table flat bordered>
        <thead>
          <tr>
            <th style="width: 75px">No</th>
            <th>Nama Aplikasi</th>
            <th style="width: 150px"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(val, i) in filteredApp" :key="i">
            <td class="text-center">
              {{ i + 1 + (pagination.currentPage - 1) * 10 }}
            </td>
            <td>{{ val }}</td>
            <td>
              <div class="row justify-evenly">
                <q-btn
                  flat
                  dense
                  color="secondary"
                  icon="edit"
                  @click="
                    type = 'edit';
                    appType = val;
                    lastType = val;
                    settings = true;
                  "
                ></q-btn>
                <q-btn
                  flat
                  dense
                  color="negative"
                  icon="delete_forever"
                  @click="
                    appType = val;
                    delguard = true;
                  "
                ></q-btn>
              </div>
            </td>
          </tr>
        </tbody>
      </q-markup-table>
    </q-scroll-area>

    <div class="full-width row justify-center">
      <q-pagination
        v-model="pagination.currentPage"
        :max="pagination.totalPage"
        input
        input-class="text-orange-10"
        class="q-my-md"
      />
      <q-btn
        class="no-border-radius full-width"
        color="primary"
        label="TAMBAH APLIKASI"
        @click="
          type = 'add';
          settings = true;
        "
      ></q-btn>
    </div>
    <q-dialog v-model="settings" persistent>
      <q-card>
        <q-card-section>
          <q-input outlined label="Nama Aplikasi" v-model="appType"></q-input>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            flat
            color="primary"
            label="Batal"
            @click="appType = ''"
            v-close-popup
          ></q-btn>
          <q-btn
            unelevated
            color="primary"
            v-if="type == 'add'"
            @click="addApp"
            label="Simpan"
            v-close-popup
          ></q-btn>
          <q-btn
            unelevated
            color="primary"
            v-if="type == 'edit'"
            @click="editApp"
            label="Edit"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="delguard" persistent>
      <q-card>
        <q-card-section>
          Apa anda yakin ingin menghapus Aplikasi <b>{{ appType }}</b> ?
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            flat
            color="primary"
            label="Tidak"
            @click="appType = ''"
            v-close-popup
          ></q-btn>
          <q-btn
            unelevated
            color="primary"
            @click="delApp"
            label="Ya"
            v-close-popup
          ></q-btn>
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { computed, ref, inject, reactive } from "vue";
import { onMounted } from "vue";
import moment from "moment";
import { useStore } from "vuex";
import { useQuasar } from "quasar";
import { useRouter } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const $http = inject("$http");
    const $q = useQuasar();

    let pagination = reactive({
      searchTerm: "",
      currentPage: 1,
      totalPage: 1,
      rowPerPage: 10,
    });
    let listApp = ref([]);

    let getApp = async () => {
      let resp = await $http.get(`/list-aplikasi`);
      listApp.value = resp.data;
    };

    let filteredApp = computed(() => {
      let app = JSON.parse(JSON.stringify(listApp.value));

      app = app.filter((val) => {
        return (
          val.toLowerCase().indexOf(pagination.searchTerm.toLowerCase()) != -1
        );
      });

      let ret = [];
      for (let i = 0; i < 10; i++) {
        if (app[(pagination.currentPage - 1) * 10 + i]) {
          ret.push(app[(pagination.currentPage - 1) * 10 + i]);
        }
      }

      pagination.totalPage = Math.ceil(app.length / pagination.rowPerPage);

      return ret;
    });

    let checkApp = async (val) => {
      let temp = await listApp.value.find((el) => {
        return el.toLowerCase() == val.toLowerCase();
      });
      if (temp) {
        return false;
      } else {
        return true;
      }
    };

    let appType = ref("");
    let lastType = ref("");

    let addApp = async () => {
      let resp = await checkApp(appType.value);
      if (resp) {
        let payload = {
          type: appType.value,
        };
        await $http.post(`/add-aplikasi`, payload);

        $q.notify({
          message: "Aplikasi Berhasil Ditambahkan!",
          color: "positive",
        });
        await getApp();
        appType.value = "";
      } else {
        $q.notify({ message: "Aplikasi Sudah Terdaftar!", color: "negative" });
        pagination.searchTerm = appType.value;
        appType.value = "";
      }
    };

    let editApp = async () => {
      let resp = checkApp(appType.value);
      if (resp) {
        let payload = {
          type: appType.value,
          last_type: lastType.value,
        };
        await $http.post(`/edit-aplikasi`, payload);

        $q.notify({
          message: "Nama Aplikasi Berhasil DIRUBAH!",
          color: "positive",
        });
        await getApp();
        appType.value = "";
      } else {
        $q.notify({ message: "Aplikasi Sudah Terdaftar!", color: "negative" });
        pagination.searchTerm = appType.value;
        appType.value = "";
      }
    };

    let delApp = async () => {
      let payload = {
        type: appType.value,
      };
      await $http.post(`/hapus-aplikasi`, payload);

      $q.notify({ message: "Aplikasi Berhasil Dihapus!", color: "positive" });
      await getApp();
      appType.value = "";
    };

    onMounted(async () => {
      try {
        $q.loading.show({ message: "Mohon tunggu sebentar" });
        await getApp();
        $q.loading.hide();
      } catch (err) {
        $q.loading.hide();
      }
    });

    return {
      delguard: ref(false),
      settings: ref(false),
      type: ref(""),
      pagination,
      moment,
      store,
      filteredApp,
      appType,
      lastType,
      getApp,
      addApp,
      editApp,
      delApp,
      checkApp,
    };
  },
};
</script>
